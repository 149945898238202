import React, { useEffect, useState } from 'react';
import { NoCookies } from '../Asests/Svg';

const CookieDisabled = (props: any) => {
  const [isEnable, setIsEnable] = useState(true);
  useEffect(() => {
    setIsEnable(navigator.cookieEnabled);
  }, []);
  window.addEventListener('enabled', () => {
    setIsEnable(true);
  });

  window.addEventListener('disabled', () => {
    setIsEnable(false);
  });

  if (isEnable) {
    return props.children;
  } else {
    return (
      <div className='page-container'>
        <div className='page-content'>
          <span className='page-illustration'>{NoCookies}</span>
          <h3 className='page-heading'>Cookie Turned Off</h3>
          <p className='page-description'>
            Oops! Your browser seems to have cookies disabled. Please enable
            cookies from the browser settings to use our app.
          </p>
        </div>
      </div>
    );
  }
};

export default CookieDisabled;
